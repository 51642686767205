import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { ToasterService } from 'src/app/services/toaster/toaster.service';


@Component({
  selector: 'app-quiz',
  templateUrl: './quiz.component.html',
  styleUrls: ['./quiz.component.scss'],
})
export class QuizComponent implements OnInit {
  @Input() data;
  @Output() view = new EventEmitter();
  @Output() content = new EventEmitter();


  total_quiz_count: any;
  course_quiz_details: any = [];
  CourseArr: any = [];
  PreviousIndex: any = 0;
  course_id: any;
  course_quiz_id: any;
  course_quiz_option_id: any = null;
  optionsAns: number = 0
  SelectContentArr: any;
  CurentQuestion: any;
  TotalQuestion: any;
  PreviousAnsIndex: any = null;
  is_right_option: boolean = false;
  subindex;
  quiz_type;
  QuizAnswer: boolean = false;
  buttonDisabled: boolean = false;
  BackBtnCounter: number = 0;
  quiz_result: any;
  disabled: boolean = false;
  alert1: any;
  question: any;
  right: boolean;
  old_data: any;
  disableAns: boolean = false;

  // k-dev
  userToaster: string;
  userToasterFlag;
  course_type: any;
  show_answer = 0;
  ngDoCheck() {
    if (this.old_data?.data?.total_quiz_count != this.data?.data?.total_quiz_count) {
      this.show_answer = this.data?.data?.show_answer?this.data?.data?.show_answer:0;
      this.userToasterFlag = null;
      this.old_data = JSON.parse(JSON.stringify(this.data));
      this.disableAns = false;
      // console.log(this.data);
      this.course_type = this.data.data.course_quiz_details[0].quiz_type;
      this.getData();
      this.right = undefined;
    }
    else {
      // console.log('same')
    }

  }

  constructor(private toast: ToasterService) { }

  ngOnInit() {
    if (this.data) {
      this.getData();
      this.old_data = JSON.parse(JSON.stringify(this.data));
      

    }

  }

  emitEvent(text) {
    this.view.emit(text);
    this.content.emit(text)
  }

  getData() {
    this.total_quiz_count = this.data?.data?.total_quiz_count;
    this.course_quiz_details = this.data?.data?.course_quiz_details;
    // console.log(this.course_quiz_details)
    this.CourseArr = this.course_quiz_details.filter((item, index) => {
      return index == this.PreviousIndex;
    });

    var TotalPart = this.total_quiz_count.split("/");
    this.TotalQuestion = TotalPart[1];
    this.CurentQuestion = (100 / this.TotalQuestion) * TotalPart[0];
    this.question = this.course_quiz_details.course_quiz_details
  }

  onClick(data, i) {
    this.disableAns = true;
    if ( this.data.data.completed_course_flag == undefined || this.data.data.completed_course_flag == false) {
      this.view.emit({ disabled: false, completed: false, selectedOption: data, baseData: this.data });
      this.disabled = true;
      if (data.is_right_option == 0) {
        this.right = false;
        this.userToasterFlag = 'wrong';
        this.userToaster = 'You got the wrong Answer!';
        if(this.course_type == 'text'){
          
          if(this.show_answer == 1){
            document.getElementById('option' + i).setAttribute("class", "wrong");
            document.getElementById('text' + i).setAttribute("class", "wrong-an");
          }else{
            document.getElementById('option' + i).setAttribute("class", "neutral");
            document.getElementById('text' + i).setAttribute("class", "wrong-an");
          }
          // var foundIndex = this.CourseArr[0].course_quiz_options.findIndex(x => x.is_right_option == 1);
          // document.getElementById('option'+foundIndex).setAttribute("class", "right");
          // document.getElementById('text'+foundIndex).setAttribute("class", "right-an");
        }else{
          // document.getElementById('option' + i).setAttribute("class", "wrong");
          // document.getElementById('text' + i).setAttribute("class", "wrong-an");
          // var foundIndex = this.CourseArr[0].course_quiz_options.findIndex(x => x.is_right_option == 1);
          // document.getElementById('option'+foundIndex).setAttribute("class", "right");
          // document.getElementById('text'+foundIndex).setAttribute("class", "right-an");
          document.getElementById('option' + i).setAttribute("class", "wrongImg");
        }
        
      }
      else {
        this.right = true;
        // this.toast.presentToast("You got the right Answer");
        this.userToasterFlag = 'right';
        this.userToaster = 'You got the right Answer!';
        if(this.course_type == 'text'){
          

          if(this.show_answer == 1){
            document.getElementById('option' + i).setAttribute("class", "right");
            document.getElementById('text' + i).setAttribute("class", "right-an");
          }else{
            document.getElementById('option' + i).setAttribute("class", "neutral");
            document.getElementById('text' + i).setAttribute("class", "right-an");
          }
        }else{
          document.getElementById('option' + i).setAttribute("class", "rightImg");
          
        }
        

      }
    }
    else {
      this.view.emit({ disabled: false, completed: true, selectedOption: data, baseData: this.data });
      if (data.is_right_option == 0) {
        this.right = false;
        this.userToasterFlag = 'wrong';
        this.userToaster = 'You got the wrong Answer!';
        if(this.course_type == 'text'){
          document.getElementById('option' + i).setAttribute("class", "wrong");
          document.getElementById('text' + i).setAttribute("class", "wrong-an");
          var foundIndex = this.CourseArr[0].course_quiz_options.findIndex(x => x.is_right_option == 1);
        }else{
          document.getElementById('option' + i).setAttribute("class", "wrongImg");
        }
        
      }
      else {
        this.right = true;
        // this.toast.presentToast("You got the right Answer");
        this.userToasterFlag = 'right';
        this.userToaster = 'You got the right Answer!';
        if(this.course_type == 'text'){
          document.getElementById('option' + i).setAttribute("class", "right");
          document.getElementById('text' + i).setAttribute("class", "right-an");
        }else{
          document.getElementById('option' + i).setAttribute("class", "rightImg");
        }
      }
    }
  }
}
