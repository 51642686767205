import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { OrdersummaryModalPageRoutingModule } from './ordersummary-modal-routing.module';

import { OrdersummaryModalPage } from './ordersummary-modal.page';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    OrdersummaryModalPageRoutingModule
  ],
  declarations: [OrdersummaryModalPage]
})
export class OrdersummaryModalPageModule {}
