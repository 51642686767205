import { EventEmitter, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  language: string = this.transLateService.currentLang;
  $changeLanguage = new EventEmitter();
  selectedLanguage = '';

  constructor(public transLateService: TranslateService) {
    transLateService.setDefaultLang('en');
  }

  initializeLanguage() {
    this.transLateService.setDefaultLang('en');
  }


  setInitialAppLanguage() {
    let language = this.transLateService.getBrowserLang();
    //console.log("browser lang", language)
    this.transLateService.setDefaultLang(language);
    let val = localStorage.getItem('SELECTED_LANGUAGE');
    if (val) {
      this.setLanguage(val);
      this.selectedLanguage = val;
    }
  }


  setLanguage(lang) {
    this.transLateService.use(lang);
    this.selectedLanguage = lang;
    localStorage.setItem('SELECTED_LANGUAGE', lang)
    // console.log("selected language= ", lang);
    var data = {
      type: 'language',
      data: lang
    }
    this.$changeLanguage.emit(data);
  }




}
