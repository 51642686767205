import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular'; 
import { FirebaseAnalytics } from '@ionic-native/firebase-analytics/ngx';
/*
  Generated class for the AnalyticsProvider provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
@Injectable()
export class AnalyticsProvider {

  constructor(private firebaseX: FirebaseAnalytics,
    private platform: Platform) {

  }

  logEvent(name: string, properties: object) {
    this.platform.ready().then(() => {
      if ( this.platform.is('cordova') ) {
        this.firebaseX.logEvent(name, properties).then(data=>{
          console.log(data)
        }).catch(err=>{
          console.log(err)
        }) // Ex: "select_content", {content_type: "page_view", item_id: "home"}
      } else console.log("NOT Cordova");
    });
  }

  setUserProperty(key: string, value: string) {
    this.platform.ready().then(() => {
      if ( this.platform.is('cordova') ) {
        this.firebaseX.setUserProperty(key, value).then(data=>console.log(data)).catch(err=>{

          console.log(err)
        });
      }
    });
  }

  // Tracks an 'screen_view' event in Firebase Analytics
  trackScreen(name: string) {
    this.platform.ready().then(() => {
      if ( this.platform.is('cordova') ) {
        this.firebaseX.setCurrentScreen(name).then(data=>{
          console.log(data);
        }).catch(err=>{
          console.log(err)
        })
      }
    });
  }

  setUserId(uid: string) {
    console.log("setUserId is calling", uid)

debugger
    this.platform.ready().then(() => {
      if ( this.platform.is('cordova') ) {
        this.firebaseX.setUserId(uid).then(data=>{
          console.log("sdfsfsdsfdff",data);
        }).catch(err=>{
          console.log(err)
        })
      }
    });
  }

  setEnabled(val : boolean){
    this.platform.ready().then(() => {
      if ( this.platform.is('cordova') ) {
        this.firebaseX.setEnabled(val).then(data=>{
          console.log(data);
        }).catch(err=>{
          console.log(err)
        })
      }

    });

  }


}
