import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AnalyticsProvider } from "providers/analytics/analytics";

@Component({
  selector: 'app-bank-verification-model',
  templateUrl: './bank-verification-model.page.html',
  styleUrls: ['./bank-verification-model.page.scss'],
})
export class BankVerificationModelPage implements OnInit {
  successDetail:any
  userName:any
  flag:any
  
 constructor(
  private modalController: ModalController,
  private analytics: AnalyticsProvider,
  ) { }

 ngOnInit() {
 }

 ionViewDidEnter() {
   this.analytics.trackScreen('BankVerificationModelPage')
 }

 closeModal(state?) {
   this.modalController.dismiss(state)
 }

}
