import { Component, OnInit, Input ,Output} from '@angular/core';

import { EventEmitter } from '@angular/core';

@Component({
  selector: 'app-image-card',
  templateUrl: './image-card.component.html',
  styleUrls: ['./image-card.component.scss'],
})



export class ImageCardComponent implements OnInit {

  @Output() imageCardOnClick = new EventEmitter();


  @Input() data;

  constructor() { }

  ngOnInit() {}


}
