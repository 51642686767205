  import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';


@Component({
  selector: 'app-invoice-completed-card',
  templateUrl: './invoice-completed-card.component.html',
  styleUrls: ['./invoice-completed-card.component.scss'],
})
export class InvoiceCompletedCardComponent implements OnInit {

  @Input() data;
  @Output() view = new EventEmitter();
  @Output() content = new EventEmitter();
  constructor() { }

  ngOnInit() {}
  emitEvent(text){
    // console.log(text);
    this.view.emit(text);
    this.content.emit(text)
  }
}
