import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-sales-invoice-component',
  templateUrl: './sales-invoice-component.component.html',
  styleUrls: ['./sales-invoice-component.component.scss'],
})
export class SalesInvoiceComponentComponent implements OnInit {
  @Input() data;
  @Output() invoiceData = new EventEmitter;
  constructor() { }


  ngOnInit() {}

  invoiceAction(action){
    this.invoiceData.emit(action);
  }

}
