import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ModalController, NavController, Platform } from '@ionic/angular';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';
import { ApiService } from 'src/app/services/http/api.service';
import { ApiConfiguration } from 'src/app/services/http/api-configuration';
import { AnalyticsProvider } from 'providers/analytics/analytics';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
@Component({
  selector: 'app-refer-earn',
  templateUrl: './refer-earn.page.html',
  styleUrls: ['./refer-earn.page.scss'],
})
export class ReferEarnPage implements OnInit {
  referral_code
  scan
  code: string = '';

  private scrollContainer: any;
  formGroup

  constructor(
    private modalController: ModalController,
    private router: NavController,
    private socialSharing: SocialSharing,
    private platform: Platform,
    private analytics: AnalyticsProvider,
    private fb: FormBuilder



  ) { }
  ngOnInit() {
  }

  private scrollToBottom(): void {
    this.scrollContainer.scrollTop = this.scrollContainer.scrollHeight;
  }
  ionViewDidEnter() {
    this.offerCodeForm()

    this.analytics.trackScreen('ReferEarnPage')
  }
  submitted = false;

  onSubmit(value) {
    this.submitted = true;
    if (this.formGroup.invalid) {
      return;
    }
    if (value.tearm == false) {
      return
    }

    let obj = {
      action: 'coupon',
      code: value.offer_code.toUpperCase()

    }
    this.modalController.dismiss(obj);
  }

  closeModal(data) {
    let obj = {
      action: 'close',
      code: ''

    }
    this.modalController.dismiss(obj);
  }

  referUser() {
    let deepLink = '';
    if (this.platform.is('android')) {
      deepLink = 'https://qrcode.qrcodemaker.bigcityvoucher.co.in/wipro.php';
    } else {
      // console.log('IOS');
      deepLink = 'https://qrcode.qrcodemaker.bigcityvoucher.co.in/wipro.php';
    }
    this.socialSharing.share(`Hello! You've been invited to Wipro Urja an Electrician Loyalty Program. Click here ${deepLink} to Register and earn cashbacks! Use the code ${this.referral_code} while registration.`, "", null, deepLink)
      .then(() => {
        this.closeModal('close')
      })
      .catch(() => {
      });
  }
  onCodeChange() {
    console.log(this.code.length)
    this.code = this.code.replace(/[^a-zA-Z0-9]/g, '');
  }
  isValidPattern() {
    return /^[a-zA-Z0-9]{12,}$/.test(this.code);
  }

  offerCodeForm() {
    this.formGroup = this.fb.group({
      offer_code: new FormControl("", [Validators.required, Validators.pattern(/^[a-zA-Z0-9\s,'-]*$/), Validators.minLength(12), Validators.maxLength(12)]),
      tearm: new FormControl(false, [Validators.required])
    });
  }

  get f() {
    return this.formGroup.controls;
  }
}
