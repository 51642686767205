import { Component, OnInit } from '@angular/core';
import { ModalController, NavController } from '@ionic/angular';
import { AnalyticsProvider } from 'providers/analytics/analytics';

@Component({
  selector: 'app-common-succes-modal',
  templateUrl: './common-succes-modal.page.html',
  styleUrls: ['./common-succes-modal.page.scss'],
})
export class CommonSuccesModalPage implements OnInit {
  info:any
  constructor(
    private modalController : ModalController,
    private router: NavController,
    private analytics: AnalyticsProvider 
    ) { }
    ngOnInit() {
    }
    ionViewDidEnter(){
    this.analytics.trackScreen('CommonSuccesModalPage')
    console.log(this.info)
  }

  closeModal(data) {
    console.log("here")
    this.router.navigateForward("tabs-page/profilepage")
    this.modalController.dismiss(data)
    
  }
}
