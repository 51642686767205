import { NavController, Platform } from '@ionic/angular';
import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { BehaviorSubject, EMPTY } from 'rxjs';
import { Observable as __Observable } from 'rxjs';
import { Router } from '@angular/router';

const TOKEN_KEY = 'auth-token';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {


  authenticationState = new BehaviorSubject(false);
 
  constructor(private storage: Storage, private plt: Platform, private nav: NavController,private router : Router) { 
    this.plt.ready().then(() => {
      this.checkToken();
    });
  }

  checkToken() {
    // console.log("url",this.router.url)
    this.storage.get(TOKEN_KEY).then(res => {
      if (res) {
        this.authenticationState.next(true);
      }
    })
  }


  
  getAuthToken() {
    return window.localStorage.getItem(TOKEN_KEY);
  }
  login(token) {
    return this.storage.set(TOKEN_KEY, 'Bearer '+token).then(() => {
      window.localStorage.setItem(TOKEN_KEY,'Bearer '+token);
      this.authenticationState.next(true);
      
    });
  }

  logout() {
    return this.storage.remove(TOKEN_KEY).then(() => {
      let fcmToken = localStorage.getItem('fcm')
      window.localStorage.clear();
      localStorage.setItem('fcm', fcmToken)
      this.authenticationState.next(false);
      this.router.navigate(['/onboarding'], { replaceUrl: true });
    });
  }

  isAuthenticated() {
    return this.authenticationState.value;
  }

}