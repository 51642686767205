import { Component, OnInit } from "@angular/core";
import { ModalController, NavController } from "@ionic/angular";
import { AnalyticsProvider } from "providers/analytics/analytics";

@Component({
  selector: "app-order-success-modal",
  templateUrl: "./order-success-modal.page.html",
  styleUrls: ["./order-success-modal.page.scss"],
})
export class OrderSuccessModalPage implements OnInit {
  couponData: any;
  userName: any;
  flag: any;

  constructor(
    private modalController: ModalController,
    private navCtrl: NavController,
    private analytics: AnalyticsProvider 
    ) { }
    ionViewDidEnter() {
      this.analytics.trackScreen('OrderSuccessModalPage')
    }  ngOnInit() { }
  closeModal(val) {
    this.modalController.dismiss(val);

  }
}
