import { Component, OnInit } from '@angular/core';
import { ModalController, NavController } from '@ionic/angular';
import { AnalyticsProvider } from 'providers/analytics/analytics';
import { ToasterService } from 'src/app/services/toaster/toaster.service';

@Component({
  selector: 'app-scanner-modal',
  templateUrl: './scanner-modal.page.html',
  styleUrls: ['./scanner-modal.page.scss'],
})
export class ScannerModalPage implements OnInit {
  scannerResponse

  constructor(private modalController: ModalController,
    private router: NavController,
    private toast: ToasterService,
    private analytics: AnalyticsProvider 
    ) { }
    ionViewDidEnter() {
      this.analytics.trackScreen('ScannerModalPage')
    }  ngOnInit() {
    console.log(this.scannerResponse)
  }
  closeModal(val) {
    // this.toast.presentToast(this.scannerResponse.message)
    console.log(val)
    this.modalController.dismiss(val);
  }

}
