import { Injectable } from '@angular/core';
import { AppVersion } from '@ionic-native/app-version/ngx';
import { AlertController, Platform } from '@ionic/angular';
import { Market } from '@ionic-native/market/ngx';
import { ApiService } from "../http/api.service";
import { ApiConfiguration } from "../http/api-configuration";

@Injectable({
  providedIn: 'root'
})
export class UpdateService {

  json: any = {
    type: 2, //1: Update 2: maintenance,
    current: '1.0.0',
    message: {
      msg: "There is a Majour update in our app. Please update to continue", //text will change for maintenance
      btn: "Update"
    }
  }

  version;
  appInfo: any = {}
  constructor(
    private alertController: AlertController,
    private appVersion: AppVersion,
    private plt: Platform,
    private market: Market,
    private apiService: ApiService,
    private apiConfig: ApiConfiguration,
  ) { }

  async descideUpdateAction() {
    // return
    this.version = await this.appVersion.getVersionNumber();

    // if (this.json.type == 1) {
    //   this.majorUpdate(); 
    // } else if (this.json.type == 2) {
    //   this.MaintainceAlert()
    // }
    // ****API INTEGRATION*******
    this.apiService.get(this.apiConfig.getAppInfo)
      .subscribe((res: any) => {
        this.appInfo = res.data.app_info[0]

        if (this.appInfo.type == 1) {
          if (this.appInfo.current_version > this.version) {
            this.majorUpdate();
          } else {
            return
          }
        } else if (this.appInfo.type == 2) {
          this.MaintainceAlert()
        }
      }, err => {
        // console.log(err)
      });
  }

  async majorUpdate() {
    const alert = await this.alertController.create({
      cssClass: 'cargill-alert t2',
      subHeader: `There is a Majour update in our app. Please update to continue`,
      buttons: ['Update'],

      backdropDismiss: false
    });

    await alert.present();

    await alert.onDidDismiss().then(() => this.updateBlock());
  }

  updateBlock() {
    if (this.plt.is('android')) {
      this.market.open('wipro.mcb.app');
    } else {
      // console.log('IOS');
      this.market.open('id6475012649');
    }
  }



  async MaintainceAlert() {
    const alert = await this.alertController.create({
      cssClass: 'cargill-alert t2',
      subHeader: `App is under maintenance, Will be back online shortly`,
      // buttons: ['Close'],

      backdropDismiss: false
    });

    await alert.present();

    await alert.onDidDismiss().then();
  }

}
