import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ToasterService } from 'src/app/services/toaster/toaster.service';

@Component({
  selector: 'app-outlet-quiz',
  templateUrl: './outlet-quiz.component.html',
  styleUrls: ['./outlet-quiz.component.scss'],
})
export class OutletQuizComponent implements OnInit {
  @Input() data;
  @Output() userAction = new EventEmitter();
  old_data: any;
  formatedQuiz: any = {};
  CurentQuestion;
  total_quiz_count;
  isDisabled = false;
  rightAns = false;
  wrongAns = false;
  userAnswer: any;
  userToaster: string;
  userToasterFlag;
  course_type: any;
  show_answer = 0;
  constructor(private toast: ToasterService) { }
  ngDoCheck() {
    if (this.data) {
      this.show_answer = this.data.show_answer ? this.data.show_answer : 0;
      //console.log("show_answer", this.show_answer);
      if (this.old_data?.total_quiz_count != this.data?.total_quiz_count) {
        this.userToasterFlag = null;
        this.isDisabled = false;
        this.old_data = JSON.parse(JSON.stringify(this.data));
        //console.log("this.data::", this.data);
        this.course_type = this.data.quizdata.question_type;
        this.formatingData();
      }
    }
  }
  ngOnInit() {
    if (this.data) {
      this.old_data = JSON.parse(JSON.stringify(this.data));
    }
  }
  formatingData() {
    this.total_quiz_count = this.data.total_quiz_count;
    let course_quiz_details = this.data.course_quiz_details;

    let TotalPart = this.total_quiz_count.split("/");
    let TotalQuestion = TotalPart[1];
    this.CurentQuestion = (100 / TotalQuestion) * TotalPart[0];
    // this.question = this.course_quiz_details.course_quiz_details
    this.formatingQuiz();
  }
  formatingQuiz() {
    this.formatedQuiz.title = this.data?.quizdata?.question;
    let options: any = [];
    for (const property in this.data?.options) {
      options.push({
        id: property,
        name: this.data?.options[property],
        class: ''
      });
    }

    this.formatedQuiz.options = options;
    //console.log("before", this.formatedQuiz.options);
    let data = this.formatedQuiz.options;
    this.formatedQuiz.options = data.filter((val) => val.name != "");
    //console.log("now", this.formatedQuiz.options);
  }
  answerQuiz(ans) {
    this.isDisabled = true;
    this.userAnswer = ans;
    if (ans.id == this.data?.quizdata.answer) {
      this.formatedQuiz?.options.forEach(element => {
        if (ans.id == element.id) {
          if (this.course_type == 1) {
            if (this.show_answer == 0) {
              element.class = 'neutral';
            } else {
              element.class = 'right';
            }
          } else {
            element.class = 'rightImg';
          }
          this.userToasterFlag = 'right';
          this.userToaster = 'You got the right Answer!';
        }
      });
    } else {
      this.formatedQuiz?.options.forEach(element => {
        if (ans.id == element.id) {
          if (this.course_type == 1) {
            if (this.show_answer == 0) {
              element.class = 'neutral';
            } else {
              element.class = 'wrong';
            }
          } else {
            element.class = 'wrongImg';
          }
          this.userToasterFlag = 'wrong';
          // this.toast.presentToast("Wrong Answer");
          this.userToaster = 'You got the wrong Answer!';

        }
        // if(this.data?.quizdata.answer == element.id){
        //   element.class = 'rightWhenWrong';
        // }
      });
    }
    this.sendAnswer();
  }
  sendAnswer() {
    let data = {
      quiz_type: this.data.quizdata.quiz_type,
      task_id: this.data.quizdata.task_id,
      answer: this.userAnswer.id,
      question_id: this.data.quizdata.task_question_id
    }
    this.userAction.emit(data);
  }
}
