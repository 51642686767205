import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AnalyticsProvider } from 'providers/analytics/analytics';
import { ApiConfiguration } from 'src/app/services/http/api-configuration';
import { ApiService } from 'src/app/services/http/api.service';

@Component({
  selector: 'app-ordersummary-modal',
  templateUrl: './ordersummary-modal.page.html',
  styleUrls: ['./ordersummary-modal.page.scss'],
})
export class OrdersummaryModalPage implements OnInit {
  successDetail: any
  userName: any
  flag: any
  constructor(
    private modalController: ModalController,
    private apiService: ApiService,
    private apiConfig: ApiConfiguration,
    private analytics: AnalyticsProvider 
    ) { }
    ionViewDidEnter() {
      this.analytics.trackScreen('OrdersummaryModalPage')
    }

  ngOnInit() {
  }
  ionViewWillEnter() {

  }

  getTrasectionDetail() {
    this.apiService.get(this.apiConfig.transectionDetail + `?program_id=&id=`).subscribe((res: any) => {
      console.log(res)
    })
  }


  closeModal() {
    console.log("hello")
    this.modalController.dismiss()
  }





}
